import React, { Fragment, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/layout/Navbar';
import Landing from './components/layout/Landing';
import { Provider } from 'react-redux';
import store from './store';

import Cheats from './components/cheats/Cheats';

import './App.css';

function App() {
  return (
    <Provider store={store}>
      <Router>
        <Fragment>
          {/* <Navbar /> */}
          <Routes>
            <Route exact path='/' element={ <Landing/> } />
            <Route exact path='/cheats' element={ <Cheats/> } />
          </Routes>
        </Fragment>
      </Router>
    </Provider>
  );
}

export default App;