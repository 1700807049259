import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

export const Cheats = () => {
  const [data, setData] = useState({
    appName: '',
    appCode: ''
  });

  useEffect(() => {
    async function fetchData() {
      const response = await fetch('/api/cheats')
        .then(response => {
          setData(response.json())
        });
    }
    fetchData();
  }, []);

  return (
    <div>
      <h1>Cheats:</h1>
      {data && <div>{data.appName} - {data.appCode}</div>}
    </div>
  );
};

export default connect()(Cheats);